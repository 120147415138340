import { Route } from 'react-router-dom';

import Header from './Menu/Header';
import Landing from './Main/Landing/Landing';
import Technology from './Main/Technology/Technology';
import Careers from './Main/Careers/Carrers';
import OurPeople from './Main/OurPeople/OurPeople';
import News from './Main/News/News';
import Footer from './Footer/Footer';


import './App.css';

function App() {
  return (
    <div>
      <Header></Header>
      <main>
        <Route path='/' exact>
          <Landing></Landing>
        </Route>
        <Route path='/careers'>
          <Careers></Careers>
        </Route>
        <Route path='/technology'>
          <Technology></Technology>
        </Route>
        <Route path='/our_people'>
          <OurPeople></OurPeople>
        </Route>
        <Route path='/news'>
          <News></News>
        </Route>
      </main>
      <Footer></Footer>
      
    </div>
    
  );
}

export default App;
