import Button from '../../UI/Button';
import  './CareerBlock.css';

import imgBg from '../../Assets/backgraund.svg';

const CareerBlock = () => {
    return <div className='career-block'>
        <div className='img-background-container'>
            <img src={imgBg} alt='backgraund' className='img-backgraund'></img>
        </div>
        <div className='foto-position'>
            <div className='career-image-wrapper'>
            <div className='career-image'></div>
            <div className='elips-small' id='elips1'></div>
            <div className='elips-big' id='elips2'></div>
            <div className='elips-small' id='elips3'></div>
            <div className='elips-big' id='elips4'></div>
            <div className='elips-small' id='elips5'></div>
            <div className='elips-big' id='elips6'></div>
        </div>
        </div>
        
        <div className='block-position'>
            <div className='absolut-content-wrapper'>
                <div className='title title-center '>Join us in builing the future together</div>
                <Button href='' name='VIEW CAREER OPPORTUNITIES'></Button>
            </div>
        </div>
        

    </div>

}

export default CareerBlock;