import  './DevicesBlock.css';


const DevicesBlock = () => {

    return <div className='devices-block'>
         <div className='devices-container'>
            <div className='title title-center'>Mear on Your Device</div>
            <div className='text'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit aliquam fermentum feugiat in eget.</div>
        </div>
        <div className='devices center'> 
            <a href='/' id='apple'> </a>
            <a href='/' id='googl'> </a>
            <a href='/' id='x-box'> </a>
            <a href='/' id='microsoft'> </a>
        </div>

    </div>

}

export default DevicesBlock;