import {createStore} from 'redux'

const navReducer = (state = {navState: false}, action) => {
    if(action.type === 'open') {
        return {navState : true}
    }
    if(action.type === 'close') {
        return {navState : false}
    }
    return state

}

const store = createStore(navReducer);

export default store;