import FirstBlock from './FirstBlock';
import VideoBlock from './VideoBlock';
import MissionBlock from './MissionBlock';
import TimerBlock from './TimerBlock';
import DevicesBlock from './DevicesBlock';
import NewsBlock from './NewsBlock';
import CareerBlock from './CareerBlock';




const Landing = () => {
    return <div className='main-container'>
        <FirstBlock></FirstBlock>
        <MissionBlock></MissionBlock>
        <VideoBlock></VideoBlock>
        <TimerBlock></TimerBlock>
        <CareerBlock></CareerBlock>
        <NewsBlock></NewsBlock>
        <DevicesBlock></DevicesBlock>
       
        
    </div>
};

export default Landing;