import  './NewsBlock.css';

import foto1 from '../../Assets/news-foto-1.svg'
import foto2 from '../../Assets/news-foto-2.svg'

const NewsBlock = () => {
    return <div className='news-block'>
            <div className='title left'>Latest News</div>
            <div className='news reverse cyan'>
                <div className='news-text cyan'>
                    <div className='news-title '>Featured news post</div>
                    <div className='news-content'>Lorem ipsum dolor sit amet, consectetur adipiscing</div>
                </div>
                <div className='news-foto'>
                    <img src={foto1} alt='foto1' className='foto1'></img>
                </div>
            </div>
            <div className='news purple'>
            <div className='news-foto'>
                    <img src={foto2} alt='foto2' className='foto2'></img>
                </div>
                <div className='news-text purple'>
                    <div className='news-title'>Featured news post</div>
                    <div className='news-content'>Lorem ipsum dolor sit amet, consectetur adipiscing</div>
                </div>
            </div>
        </div>
}

export default NewsBlock;