import { NavLink } from 'react-router-dom';

import  './Footer.css';



const Footer = () => {
    return (
        <footer className='footer'>
          <div className='footer-container'>
            <div className="menu-left">
              <div className="menu-wrapper">
                <h4>Company</h4>
                <NavLink to="./careers">Careers</NavLink>
                <NavLink to="./technology">Technology</NavLink>
                <NavLink to="./our_people">Our People</NavLink>
                <a href="/">Investor Relations</a>
                <NavLink to="./news">News</NavLink>
              </div>
              <div className="menu-wrapper">
                <h4>Resources</h4>
                <a href="/">For Parents</a>
                <a href="/">Education</a>
                <a href="/">Digital Well-Being</a>
                <a href="/">FAQ</a>
                <a href="/">Contact</a>
              </div>
            </div>
            <div className='menu-right'>
              <div className="icon" id='facebook' ></div>
              <div className="icon"  id='linkedin'></div>
              <div className="icon"  id='instagram'></div>
              <div className="icon"  id='twitch'></div>
              <div className="icon"  id='twitter'></div>
              <div className="icon"  id='youtube'></div>
            </div>
          </div>
          
      </footer>
        
    )
};

export default Footer;